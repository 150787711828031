<template>
    <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
        <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
            {{ errorMessage }}
        </div>
        <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
            {{ successMessage }}
        </div>
    </div>
    <section class="w-full relative flex justify-center items-center mt-[50px]">
        <div class="container max-w-[1024px] px-[20px] relative w-full flex justify-center">
            <div class="w-full rounded-[10px] lg:p-[50px] p-[20px] bg-gray-800 flex lg:flex-row flex-col justify-center items-center">
                <div class="md:w-6/12 md:pr-[10px] w-full">
                    <h1 class="lg:text-3xl text-2xl font-bold text-white">Abonnez vous pour des mises à jour quotidiennes</h1>
                    <p class="text-gray-200 text-sm font-semibold mt-[10px]">Laissez nous votre email maintenant pour être au courant des offres promotionnelles au fur et à mesure en premier</p>
                </div>
                <form @submit.prevent="subscribe()" class="md:w-6/12 md:pl-[10px] mt-[25px] lg:mt-0 w-full flex justify-end items-center relative">
                    <button type="submit" class="bg-orange-600 h-[30px] w-[100px] flex justify-center items-center text-white p-[5px] rounded-[5px] text-sm font-semibold absolute right-[5px] top-[5px] px-[10px]">
                        <span v-if="!isLoading ">S'abonner</span>
                        <div v-if="isLoading" class="spinner1">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </button>
                    <input type="email" v-model="email" name="email" autocomplete="yes" required class="w-full max-w-[400px] p-[10px] outline-none text-sm bg-white border-none rounded-[5px] font-semibold" placeholder="Entrez votre email">
                </form>
            </div>
        </div>
    </section>
    <footer class="flex w-full justify-center flex-col items-center relative lg:pt-[50px]">
        <div class="container max-w-[1024px] px-[20px] relative w-full flex justify-center items-center">
            <div class="w-full relative py-[50px] flex lg:flex-row flex-col justify-between items-start">
                <div class="md:w-[calc(35%-10px)] lg:pr-[20px]">
                    <h1 class="text-gray-800 font-bold text-2xl">Pomme City</h1>
                    <p class="text-sm font-semibold text-gray-500 my-[15px]">
                        Vente des smartphones et accessoires tout genre ( Iphone, Samsung et autres )
                    </p>
                    <div class="flex justify-start w-full items-center">
                        <a target="_blank" href="https://www.facebook.com/profile.php?id=100093957252689&mibextid=LQQJ4d" class="bg-gray-800 font-bold text-white flex justify-center items-center text-sm rounded-[5px] w-[30px] h-[30px] mr-[20px]">
                            <i class="fa-brands fa-facebook"></i>
                        </a>
                        <a target="_blank" href="https://www.tiktok.com/@pommecity?_t=8pBsPcUEG3R&_r=1" class="bg-gray-800 font-bold text-white flex justify-center items-center text-sm rounded-[5px] w-[30px] h-[30px] mr-[20px]">
                            <i class="fa-brands fa-tiktok"></i>
                        </a>
                        <a target="_blank" href="#" class="bg-gray-800 font-bold text-white flex justify-center items-center text-sm rounded-[5px] w-[30px] h-[30px] mr-[20px]">
                            <i class="fa-brands fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div class="md:w-[calc(15%-10px)] py-[20px] border-b lg:border-b-0 w-full md:py-0">
                    <h1 class="text-sm font-bold text-gray-800">Liens utiles</h1>
                    <ul>
                        <li @click="gotoAccueil()" class="text-sm cursor-pointer text-gray-500 font-semibold my-[10px]">Accueil</li>
                        <li @click="gotoOffre()" class="text-sm cursor-pointer text-gray-500 font-semibold my-[10px]">Bon plan</li>
                        <li @click="gotoBoutique()" class="text-sm cursor-pointer text-gray-500 font-semibold my-[10px]">Boutique</li>
                        <li @click="gotoComparatif()" class="text-sm cursor-pointer text-gray-500 font-semibold my-[10px]">Comparatif</li>
                        <li @click="gotoPanier()" class="text-sm cursor-pointer text-gray-500 font-semibold my-[10px]">Panier</li>
                        <li @click="gotoMention()" class="text-sm cursor-pointer text-gray-500 font-semibold my-[10px]">Mentions légales</li>
                    </ul>
                </div>
                <div class="md:w-[calc(25%-10px)] py-[20px] border-b lg:border-b-0 lg:py-0 lg:px-[10px] w-full">
                    <h1 class="text-sm font-bold text-gray-800 mb-[10px]">Nos horaires</h1>
                    <div class="w-full flex justify-between max-w-[70%] lg:max-w-full">
                        <h1 class="text-sm text-gray-600 font-semibold">Lundi - Samedi :</h1>
                        <p class="text-sm text-gray-800">9h - 21h</p>
                    </div>
                    <div class="w-full flex justify-between max-w-[70%] lg:max-w-full">
                        <h1 class="text-sm text-gray-600 font-semibold">Dimanche :</h1>
                        <p class="text-sm text-gray-800">Fermé</p>
                    </div>
                    <h1 class="text-sm font-bold text-gray-800 my-[15px]">Localisation</h1>
                    <div class="w-full text-xs flex flex-col font-semibold text-gray-500">
                        <a href="https://maps.app.goo.gl/hhxtBk8AnPjKaWZ56" target="_blank" class="hover:underline">
                            COTONOU Bar Tito, von en face de la pharmacie LE CONSEIL, premier boutique à gauche.
                        </a>
                        <a href="https://maps.app.goo.gl/QP4qDJPjswGGUSWg9" target="_blank" class="hover:underline">
                            PARAKOU Wansirou En face du restaurant Le Secret de la Vielle Marmite
                        </a>
                    </div>
                </div>
                <div class="md:w-[calc(25%-10px)] lg:px-[20px] pt-[20px] lg:pt-0">
                    <h1 class="text-sm font-bold text-gray-800 mb-[15px]">Contactez nous</h1>
                    <button @click="gotoContact()" class="text-sm font-bold p-[10px] rounded-[5px] bg-gray-800 text-white">Prendre contact</button>
                </div>
            </div>
        </div>
        <div class="w-full p-[20px] bg-gray-800 flex justify-center items-center relative">
            <p class="text-white text-xs text-center">
                &copy; Copyright Pomme city, Tous droits réservés | Développé par <a href="tel:+22941787700" class="hover:underline duration-100 font-bold">Vaybe</a>
            </p>
        </div>
    </footer>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

const router = useRouter()
const isLoading = ref(false)

function gotoAccueil() {
    router.push("/")
}
function gotoOffre() {
    router.push("/bon-plan")
}
function gotoBoutique() {
    router.push("/boutique")
}
function gotoComparatif() {
    router.push("/comparatif")
}
function gotoPanier() {
    router.push("/panier")
}
function gotoMention() {
    router.push("/mentions-legales")
}
function gotoContact() {
    router.push("/contact")
}

const email = ref('')
const successMessage = ref('')
const errorMessage = ref('')

const subscribe = async () => {
    isLoading.value = true
    try {
        const response = await axios.post('/api/newsletter/subscribe', { email: email.value });
        if (response.status === 201) {
            successMessage.value = 'Abonnement réussi à la newsletter!';
            hideSuccessMessageAfterDelay();
        }
        email.value = '';
    } catch (error) {
        if (error.response && error.response.status === 409) {
            successMessage.value = 'Cet email est déjà abonné à la newsletter.';
            hideSuccessMessageAfterDelay()
        } else {
            errorMessage.value = 'Erreur lors de l\'abonnement à la newsletter';
            hideErrorMessageAfterDelay()
        }
    } finally {
        isLoading.value = false
    }
};


const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

</script>

<style>

</style>