<template>
  <router-view/>
  <div class="w-[50px] h-[50px] shadow-11xl flex justify-center items-center rounded-[50%] overflow-hidden cursor-pointer fixed right-[25px] bottom-[25px] bg-[#25D366] z-[1000000000000000]">
      <a href="https://wa.me/+22967574609" target="_blank">
         <i class="fa-brands fa-whatsapp text-3xl font-bold text-white fa-bounce"></i>
      </a>
  </div>
</template>

<style>
.parent::-webkit-scrollbar {
  width: 0%;
  height: 0%;
}
.spinner {
   position: relative;
   width: 10px;
   height: 10px;
}

.spinner div {
   animation: spinner-4t3wzl 1.875s infinite backwards;
   background-color: rgb(234, 88, 12);
   border-radius: 50%;
   height: 100%;
   position: absolute;
   width: 100%;
}

.spinner div:nth-child(1) {
   animation-delay: 0.15s;
   background-color: rgba(234, 88, 12, 0.9);
}

.spinner div:nth-child(2) {
   animation-delay: 0.3s;
   background-color: rgba(234, 88, 12,0.8);
}

.spinner div:nth-child(3) {
   animation-delay: 0.45s;
   background-color: rgba(234, 88, 12,0.7);
}

.spinner div:nth-child(4) {
   animation-delay: 0.6s;
   background-color: rgba(234, 88, 12,0.6);
}

.spinner div:nth-child(5) {
   animation-delay: 0.75s;
   background-color: rgba(234, 88, 12,0.5);
}

@keyframes spinner-4t3wzl {
   0% {
      transform: rotate(0deg) translateY(-200%);
   }

   60%, 100% {
      transform: rotate(360deg) translateY(-200%);
   }
}

.spinner1 {
   position: relative;
   width: 5px;
   height: 5px;
}

.spinner1 div {
   animation: spinner-4t3wzl1 1.875s infinite backwards;
   background-color: #fff;
   border-radius: 50%;
   height: 100%;
   position: absolute;
   width: 100%;
}

.spinner1 div:nth-child(1) {
   animation-delay: 0.15s;
   background-color: rgba(255, 255, 255, 0.9);
}

.spinner1 div:nth-child(2) {
   animation-delay: 0.3s;
   background-color: rgba(255,255,255,0.8);
}

.spinner1 div:nth-child(3) {
   animation-delay: 0.45s;
   background-color: rgba(255,255,255,0.7);
}

.spinner1 div:nth-child(4) {
   animation-delay: 0.6s;
   background-color: rgba(255,255,255,0.6);
}

.spinner1 div:nth-child(5) {
   animation-delay: 0.75s;
   background-color: rgba(255,255,255,0.5);
}

@keyframes spinner-4t3wzl1 {
   0% {
      transform: rotate(0deg) translateY(-200%);
   }

   60%, 100% {
      transform: rotate(360deg) translateY(-200%);
   }
}
</style>
